import { List, ListItem, ListItemProps, ListProps, Theme } from '@audi/audi-ui-react';
import parse from 'html-react-parser';
import React, { FC, ReactNode, cloneElement } from 'react';
import styled from 'styled-components';
import xss from 'xss';
import { renderReactNodeWithFootnotes } from './renderReactNodeWithFootnotes';

const whiteList = {
  b: [],
  i: [],
  u: [],
  ul: [],
  li: [],
  br: [],
  p: [],
  strong: [],
  a: ['href'],
};

const StyledList = styled(List as FC<ListProps>)`
  ${({ theme }: { theme: Theme }) =>
    `
margin-block: var(${theme.responsive.spacing.m});
margin-inline-start: var(${theme.responsive.spacing.m});
  `}
`;

const StyledListItem = styled(ListItem as FC<ListItemProps>)`
  ${({ theme }: { theme: Theme }) =>
    `
      font-size: var(${theme.responsive?.typography.copy1.fontSize});
      margin-block-end: var(${theme.responsive?.spacing.s});
      margin-block-start: 0px;
    `}
`;
const createElement = (type: string, props?: object, ...children: ReactNode[]): JSX.Element => {
  if (type === 'br') {
    return React.createElement(type, props);
  }
  const renderChildren = () => renderReactNodeWithFootnotes(children);
  if (type === 'ul') {
    return React.createElement(StyledList, props, renderChildren());
  }
  if (type === 'li') {
    return React.createElement(StyledListItem, props, renderChildren());
  }
  return React.createElement(type, props, renderChildren());
};

export function convertRichText(htmlString: string) {
  return parse(
    xss(htmlString, {
      whiteList,
      stripIgnoreTag: true,
    }),
    {
      library: {
        cloneElement,
        createElement,
        isValidElement: (element: unknown): boolean => {
          return Boolean(element);
        },
      },
    },
  );
}
