import type { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import type { FC } from 'react';

interface TrackClickAttributes {
  readonly targetURL: string;
  readonly label: string;
  readonly pos: number;
  readonly action?: string;
  readonly eventNameSuffix?: string;
}

interface TrackingContextState {
  readonly impressionTrackingRef: (element: Element | null) => void;
  readonly trackClick: (attributes: TrackClickAttributes) => void;
}

const TrackingContext = createContext<TrackingContextState | null>(null);

export interface TrackingContextProviderProps {
  readonly trackingService?: TrackingServiceV2;
  readonly heading?: string;
  readonly children: React.ReactNode;
}

export interface TrackingEvent {
  event: {
    eventInfo: {
      eventAction: string;
      eventName: string;
    };
    attributes: Record<string, unknown>;
  };
}

const createTrackingPayload = (
  eventName: string,
  attributes?: Record<string, unknown>,
  action = '',
): TrackingEvent => ({
  event: {
    eventInfo: {
      eventAction: action,
      eventName: `basic teaser - ${eventName}`,
    },
    attributes: {
      componentName: 'basic-teaser',
      clickID: '',
      elementName: '',
      label: '',
      pos: '',
      targetURL: '',
      value: '',
      ...attributes,
    },
  },
});

export const TrackingContextProvider: FC<TrackingContextProviderProps> = ({
  trackingService,
  heading,
  children,
}) => {
  const impressionTrackingRef = useCallback(
    (element: Element | null) => {
      if (trackingService && element !== null) {
        trackingService.registerImpressionTracking(element, () =>
          createTrackingPayload(
            'impression',
            {
              value: heading ?? '',
            },
            'impression',
          ),
        );
      }
    },
    [trackingService],
  );

  const trackClick = useCallback(
    ({
      targetURL,
      label,
      pos,
      action: _action,
      eventNameSuffix: _eventNameSuffix,
    }: TrackClickAttributes) => {
      const action = _action ?? trackingService?.evaluateLinkType(targetURL) ?? '';
      const eventNameSuffix = _eventNameSuffix !== undefined ? ` - ${_eventNameSuffix}` : '';
      trackingService?.track(
        createTrackingPayload(
          `click on cta${eventNameSuffix}`,
          {
            value: heading ?? '',
            elementName: 'button',
            targetURL,
            label,
            pos: pos.toString(10),
          },
          action,
        ),
      );
    },
    [trackingService],
  );

  useEffect(() => {
    trackingService?.track(
      createTrackingPayload('feature app ready', undefined, 'feature app ready'),
    );
  }, [trackingService]);

  const contextValue = useMemo(
    () => ({
      impressionTrackingRef,
      trackClick,
    }),
    [impressionTrackingRef, trackClick],
  );

  return <TrackingContext.Provider value={contextValue}>{children}</TrackingContext.Provider>;
};

export const useImpressionTrackingRef = () => useContext(TrackingContext)?.impressionTrackingRef;
export const useTrackClick = () => useContext(TrackingContext)?.trackClick;
