const regex = /{(ft_[a-zA-Z0-9_-]+)}/g;

/**
 * Takes a text containing footnote placeholders with the format `{ft_some_footnote_id}` and transforms
 * it to list the footnote keys at the end using "~" as prefix and "," as separator
 *
 * @param text text containing footnote placeholders
 * @example transformToTrackingText('Stromverbrauch kombiniert{ft_nemo-fn_eec-global-disclaimer}: 23,6 / 100 km')
 * @returns string including all found footnotes listed at the end
 * e.g. "Stromgverbrauch kombiniert: 23,6 / 100 km~ft_nemo-fn_eec-global-disclaimer"
 */
export const transformToTrackingText = (text: string): string => {
  const footnotes: string[] = [];
  const textWithoutFootnotes = text.replace(regex, (_match, footnoteKey) => {
    footnotes.push(footnoteKey);
    return "";
  });
  if (footnotes.length) {
    return `${textWithoutFootnotes}~${footnotes.join(",")}`;
  }
  return text;
};
