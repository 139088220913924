import { useCallback, useSyncExternalStore } from "react";

/**
 * @param query The media query string.
 * @returns `true` if the given media query matches, otherwise `false`.
 * @returns `true` during SSR and hydration.
 */
export function useMediaQuery(query: string): boolean {
  const subscribe = useCallback(
    (listener: () => void) => {
      const mql = window.matchMedia(query);
      const abortController = new AbortController();

      mql.addEventListener("change", listener, {
        signal: abortController.signal,
      });

      return () => {
        abortController.abort();
      };
    },
    [query]
  );

  const getSnapShot = useCallback(() => window.matchMedia(query).matches, [
    query,
  ]);

  // During SSR we don't know the viewport, so we need to render every
  // variation, and only clean up the non-matching variations in the client,
  // after hydration. This is exactly what useSyncExternalStore can be used for.
  const getServerSnapShot = () => true;

  return useSyncExternalStore(subscribe, getSnapShot, getServerSnapShot);
}
