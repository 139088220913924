import { addSearchParams } from "../urlUtils";

interface Image {
  imageFormats: {
    "web-largest": string;
  };
}

/**
 * Transforms Akamai URL given by asset browser to the correct Audi URL
 * @param {string} webLargest - the webLargest URL from the asset browser - see `getWebLargestImages`
 */
function transformWebLargest(webLargest: string): string {
  // TODO change absolute and relative url handling depending on environment
  // explanation: localhost, FAM, author and renderman SSR require absolute urls, everything else should be relative
  return webLargest
    .replace(
      "https://audivmstest-a.akamaihd.net/brand/stage/brandportal/",
      "https://pre-www.audi.de/content-audi-assets/"
    )
    .replace(
      "https://audivms-a.akamaihd.net/brand/",
      "https://www.audi.de/content-audi-assets/"
    );
}

/**
 * Extracts and returns the web-largest image urls from the parsed json result of content.audi
 * @param {any} assetValue - parsed json object from the input field of the face editor.
 */
function getWebLargestImages({ image: images }: { image: Image[] }): string[] {
  if (!Array.isArray(images)) {
    throw new Error(`no image array found in given object`);
  }

  return images.map(({ imageFormats }: Image, index: number) => {
    if (!imageFormats) {
      throw new Error(`imageFormats not found in image on index ${index}`);
    }

    const { "web-largest": webLargest } = imageFormats;
    if (!webLargest) {
      throw new Error(
        `web-largest not found in imageFormats at image on index ${index}`
      );
    }
    return webLargest;
  });
}

/**
 * Extracts and returns the web-largest images from athe json result of
 * content.audi as array or returns the the image url from input
 * @param assetValue - json string or string (image url)
 * from the input field of the face editor.
 */
export function getImageUrls(assetValue: string): string[] {
  if (!assetValue.startsWith("{")) return [assetValue];

  const contetAudiImage = JSON.parse(assetValue);
  return getWebLargestImages(contetAudiImage).map(transformWebLargest);
}

/**
 * @deprecated will be removed, please use addWidthParameter instead
 *
 * Adds width parameter to the given URL. Will return given URL if anything goes wrong
 * @param url - the image url
 * @param maxWidth - the maximum width to be set as width parameter
 */
export function addImwidthParameter(url: string, maxWidth: number): string {
  return addWidthParameter(url, maxWidth);
}

/**
 * Adds width parameter to the given URL. Will return given URL if anything goes wrong
 * @param url - the image url
 * @param maxWidth - the maximum width to be set as width parameter
 */
export function addWidthParameter(url: string, maxWidth: number): string {
  return addSearchParams(url, new Map([["width", maxWidth.toString(10)]]));
}
