import {
  Listener,
  Subscribable,
  Subscription
} from '@volkswagen-onehub/feature-hub-utils';

import {FocusLayerSizeV2, InteractionLayerSizeV2, LayerManagerV2} from '../v2';

import {
  LayerDescriptionV1,
  LayerHandleV1,
  LayerManagerV1,
  LayerOptionsV1,
  RenderFunctionV1
} from './types';

export * from './types';

export class LayerManagerV1Impl extends Subscribable implements LayerManagerV1 {
  public constructor(private readonly manager: LayerManagerV2) {
    super();
  }

  public openAlert<TState, TReturn>(
    render: RenderFunctionV1<TState, TReturn>,
    state: TState,
    options?: LayerOptionsV1<TState, TReturn>
  ): LayerHandleV1<TState> {
    return this.manager.openAlert(render, state, options);
  }

  public openFocusLayer<TState, TReturn>(
    render: RenderFunctionV1<TState, TReturn>,
    state: TState,
    options?: LayerOptionsV1<TState, TReturn>
  ): LayerHandleV1<TState> {
    return this.manager.openFocusLayer(render, state, {
      ...options,
      size: FocusLayerSizeV2.A
    });
  }

  public openInteractionLayer<TState, TReturn>(
    render: RenderFunctionV1<TState, TReturn>,
    state: TState,
    options?: LayerOptionsV1<TState, TReturn>
  ): LayerHandleV1<TState> {
    return this.manager.openInteractionLayer(render, state, {
      ...options,
      size: InteractionLayerSizeV2.A
    });
  }

  public openContentLayer<TState, TReturn>(
    render: RenderFunctionV1<TState, TReturn>,
    state: TState,
    options?: LayerOptionsV1<TState, TReturn>
  ): LayerHandleV1<TState> {
    // tslint:disable-next-line: deprecation
    return this.manager.openContentLayer(render, state, options);
  }

  public getLayers(): LayerDescriptionV1[] {
    return this.manager.getLayers();
  }

  public subscribe(listener: Listener): Subscription {
    return this.manager.subscribe(listener);
  }
}
