import { useSyncExternalStore } from "react";

const noopSubscribe = () => () => undefined;
const getSnapshot = () => true;
const getServerSnapshot = () => false;

/**
 * @returns `false` during SSR and hydration, otherwise `true`
 */
export function useIsClient(): boolean {
  return useSyncExternalStore(noopSubscribe, getSnapshot, getServerSnapshot);
}
