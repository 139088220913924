import React from "react";
import reactStringReplace from "react-string-replace";
import { FootnoteReference, FootnoteReferenceV2 } from "./FootnoteReference";

export * from "./FootnoteReference";
export * from "./use-footnote-service";
export * from "./transformTrackingText";

function replaceTextWithCallbackResult(
  text: string,
  cb: (match: string, i: number) => JSX.Element
) {
  let textWithReplacedFootnotes;

  textWithReplacedFootnotes = reactStringReplace(
    text,
    /{ft_([a-zA-Z0-9_-]+)}/g,
    cb
  );

  textWithReplacedFootnotes = reactStringReplace(
    textWithReplacedFootnotes,
    /{{footnote.reference.([a-zA-Z0-9_-]+)}}/g,
    cb
  );

  return textWithReplacedFootnotes;
}

/**
 * Takes a text containing footnote placeholders with the format `{ft_some_footnote_id}` and replaces them with
 * `FootnoteReference` components which contain a link to navigate to the corresponding footnote text.
 *
 * @param text text containing footnote placeholders
 *
 * @example
 * renderTextWithFootnotesReferences(
 * 'Stromverbrauch kombiniert<sup>{ft_nemo-fn_eec-global-disclaimer}</sup>: 23,6 / 100 km'
 * )
 */
export function renderTextWithFootnotesReferences(
  text: string
): React.ReactNodeArray {
  const replaceCb = (match: string, i: number) => (
    <FootnoteReference key={i} footnoteId={match} />
  );
  return replaceTextWithCallbackResult(text, replaceCb);
}

/**
 * Takes a text containing footnote placeholders with the format `{ft_some_footnote_id}` and replaces them with
 * `FootnoteReferenceV2` components which contain a link to navigate to the corresponding footnote text.
 *
 * @param text text containing footnote placeholders
 *
 * @example
 * renderTextWithFootnotesReferences(
 * 'Stromverbrauch kombiniert<sup>{ft_nemo-fn_eec-global-disclaimer}</sup>: 23,6 / 100 km'
 * )
 */
export function renderTextWithFootnotesReferencesV2(
  text: string,
  ariaText?: string
): React.ReactNode[] {
  const replaceCb = (match: string, i: number) => (
    <FootnoteReferenceV2
      key={i}
      footnoteId={match}
      footnoteAriaLabel={ariaText}
    />
  );
  return replaceTextWithCallbackResult(text, replaceCb);
}
