import React, { useMemo } from "react";
import { StyledUniversalBreakpointWrapper } from "./UniversalBreakpointWrapper.styles";
import { useMediaQuery } from "./useMediaQuery";

export interface UniversalBreakpointWrapperProps {
  readonly minWidth?: number;
  readonly maxWidth?: number;
  readonly minAspectRatio?: string;
  readonly maxAspectRatio?: string;
  readonly orientation?: "landscape" | "portrait";
  readonly display?: string;
  readonly children: React.ReactNode;
  readonly className?: string;
}

export function UniversalBreakpointWrapper({
  minWidth = 0,
  maxWidth = Infinity,
  minAspectRatio,
  maxAspectRatio,
  orientation,
  display = "block",
  children,
  className,
}: UniversalBreakpointWrapperProps): JSX.Element | null {
  const mediaQuery = useMemo(() => {
    const mediaQueryParts: string[] = [];

    if (minWidth > 0) {
      mediaQueryParts.push(`(min-width: ${minWidth}px)`);
    }

    if (Number.isFinite(maxWidth)) {
      mediaQueryParts.push(`(max-width: ${maxWidth}px)`);
    }

    if (minAspectRatio) {
      mediaQueryParts.push(`(min-aspect-ratio: ${minAspectRatio})`);
    }

    if (maxAspectRatio) {
      mediaQueryParts.push(`(max-aspect-ratio: ${maxAspectRatio})`);
    }

    if (orientation) {
      mediaQueryParts.push(`(orientation: ${orientation})`);
    }

    return mediaQueryParts.join(" and ");
  }, [minWidth, maxWidth, minAspectRatio, maxAspectRatio, orientation]);

  const mediaQueryMatches = useMediaQuery(mediaQuery);

  if (!mediaQueryMatches) {
    return null;
  }

  return (
    <StyledUniversalBreakpointWrapper
      mediaQuery={mediaQuery}
      cssDisplay={display}
      className={className}
    >
      {children}
    </StyledUniversalBreakpointWrapper>
  );
}
