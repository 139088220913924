import React from 'react';
import styled from 'styled-components';
import { addSearchParams } from '@oneaudi/feature-app-utils';
import { UeElement } from '@oneaudi/falcon-tools';
import { parseAssetValue } from '../utils/parseAssetValue';
import { AssetTypeImage } from '../../types';

interface ImageProps {
  asset: AssetTypeImage;
}

const Img = styled.img`
  width: 100%;
`;

const createWidthSrc = (src: string, width: string) =>
  addSearchParams(
    src,
    new Map([
      ['width', width],
      ['auto', 'webp'],
    ]),
  );

export const Image = ({ asset }: ImageProps) => {
  const { src } = parseAssetValue(asset.src.assetValue);
  const full = addSearchParams(src, new Map([['auto', 'webp']]));
  const w342 = createWidthSrc(src, '342');
  const w660 = createWidthSrc(src, '660');
  const w711 = createWidthSrc(src, '711');
  const w864 = createWidthSrc(src, '864');
  const w943 = createWidthSrc(src, '943');
  const w1422 = createWidthSrc(src, '1422');
  const w1728 = createWidthSrc(src, '1728');
  const w1886 = createWidthSrc(src, '1886');

  return (
    <div className="image__container">
      <UeElement type="media" property="assetSrc" label="image">
        {(ueElemProps) => (
          <Img
            className="screen-size-xs screen-size-s screen-size-m screen-size-l screen-size-xl screen-size-xxl"
            src={full}
            srcSet={`${w342} 342w,${w660} 660w,${w711} 711w,${w864} 864w,${w943} 943w,${w1422} 1422w,${w1728} 1728w,${w1886} 1886w`}
            sizes="(max-width:398px) 342px,(max-width:716px) 660px,(max-width:791px) 711px,(max-width:944px) 864px,(max-width:1023px) 943px,(max-width:1512px) 660px,(max-width:1614px) 711px,864px"
            alt={asset.alt ?? ''}
            loading="lazy"
            {...ueElemProps}
          />
        )}
      </UeElement>
    </div>
  );
};
