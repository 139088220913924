import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import { Children, ReactNode } from 'react';

const transform = (node: ReactNode) => {
  if (typeof node === 'string') {
    return renderTextWithFootnotesReferencesV2(node);
  }
  return node;
};

export function renderReactNodeWithFootnotes(nodes: ReactNode[]) {
  return Children.map(nodes, transform);
}
