import { Text as TextWrapper, Theme } from '@audi/audi-ui-react';
import React, { memo } from 'react';
import styled from 'styled-components';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import { UeElement } from '@oneaudi/falcon-tools';
import { convertRichText } from '../utils/textUtils';

const StyledText = styled.span`
  & p {
    margin: 0;
    padding: 0;
    ${({ theme }: { theme: Theme }) =>
      `
    margin-block-start: var(${theme.responsive.spacing.m});
    `}
  }
`;

export interface TextProps {
  readonly text: string;
}

export const RichText = memo(function RichText({ text }: TextProps) {
  const richText = convertRichText(text);
  const renderedText =
    typeof richText === 'string'
      ? renderTextWithFootnotesReferencesV2(richText.toString())
      : richText;
  return (
    <TextWrapper as="span">
      <UeElement type="text" property="text" label="Copy">
        {(ueProps) => <StyledText {...ueProps}> {renderedText} </StyledText>}
      </UeElement>
    </TextWrapper>
  );
});
