type SubscribableDefaultPayload = void;

export type Listener<
  TPayload = SubscribableDefaultPayload
> = TPayload extends void ? () => void : (payload: TPayload) => void;

export interface Subscription {
  unsubscribe(): void;
}

export class Subscribable<TPayload = SubscribableDefaultPayload> {
  private readonly listeners = new Set<Listener<TPayload>>();

  public subscribe(listener: Listener<TPayload>): Subscription {
    this.listeners.add(listener);

    return {
      unsubscribe: () => this.listeners.delete(listener),
    };
  }

  protected updateInternal(payload: TPayload): void {
    for (const listener of this.listeners) {
      listener(payload);
    }
  }
}

export class Observable<TValue> extends Subscribable<void> {
  public constructor(public value: TValue) {
    super();
  }

  public update(newValue: TValue): void {
    this.value = newValue;

    this.updateInternal();
  }
}
