import * as React from 'react';
import type {
  FeatureAppDefinition,
  FeatureAppEnvironment,
  FeatureServices,
} from '@feature-hub/core';
import type { ReactFeatureApp } from '@feature-hub/react';
import type { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import type { ContentServiceV1 } from '@oneaudi/content-service';
import type { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { ContentContextProvider, FootnoteContextProvider } from '@oneaudi/feature-app-utils';
import type { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { LayerManagerV27 } from '@volkswagen-onehub/layer-manager';
import { AudiVideoServiceInterfaceV1 } from '@oneaudi/video-service';
import type { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import { BasicTeaserContentLoader } from './components/BasicTeaser';
import type { BasicTeaserContent } from '../types';
import { LayerManagerContext } from './context';

interface FeatureServiceDependencies extends FeatureServices {
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 'audi-tracking-service': TrackingServiceV2;
  readonly 'dbad:audi-vue-formatter-service': VueFormatterServiceInterfaceV1;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'layer-manager': LayerManagerV27;
  readonly 'audi-video-service': AudiVideoServiceInterfaceV1;
  readonly 'audi-render-mode-service': RenderModeServiceV1;
}

// eslint-disable-next-line import/no-default-export
export default {
  dependencies: {
    externals: {
      react: '^18.2.0',
      'react-dom': '^18.2.0',
      'styled-components': '^5.3.1',
      '@audi/audi-ui-react': '^3',
      '@feature-hub/react': '^3',
    },
    featureServices: {
      'audi-content-service': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
      'dbad:audi-vue-formatter-service': '^1.0.0',
      'locale-service': '^1.0.0',
      'layer-manager': '^2.7.2',
    },
  },
  optionalDependencies: {
    featureServices: {
      'audi-tracking-service': '^2.0.0',
      'audi-footnote-reference-service': '^3.0.0',
      'audi-video-service': '^1.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },
  create: ({
    featureServices: {
      'audi-content-service': contentService,
      'locale-service': localeService,
      'dbad:audi-vue-formatter-service': vueFormatterService,
      'audi-footnote-reference-service': footnoteReferenceServiceScopeManager,
      'layer-manager': layerManager,
      'audi-tracking-service': trackingService,
      'audi-render-mode-service': renderModeService,
    },
    config,
    featureAppId: id,
  }: FeatureAppEnvironment<FeatureServiceDependencies, BasicTeaserContent>) => {
    if (trackingService) {
      // eslint-disable-next-line no-param-reassign
      trackingService.featureAppName = 'basic teaser';
    }
    const footnoteReferenceService =
      footnoteReferenceServiceScopeManager?.getDefaultScopeRefService();

    return {
      render: () => (
        <ContentContextProvider contentService={contentService}>
          <LayerManagerContext.Provider value={layerManager}>
            <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
              <div data-fefa-custom-id={footnoteReferenceService.getConsumerId()}>
                <UniversalEditorProvider
                  contentService={contentService}
                  renderModeService={renderModeService}
                >
                  <BasicTeaserContentLoader
                    vueFormatterService={vueFormatterService}
                    localeService={localeService}
                    trackingService={trackingService}
                    initialContent={config}
                    meta={{ id }}
                  />
                </UniversalEditorProvider>
              </div>
            </FootnoteContextProvider>
          </LayerManagerContext.Provider>
        </ContentContextProvider>
      ),
    };
  },
} as FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies>;
