// tslint:disable-next-line:no-submodule-imports
import uuid from 'uuid/v4';

import {
  LayerContentElementV2,
  LayerContentOptionsV2,
  LayerHandleV2,
  LayerOptionsV2,
  LayerV2,
  RenderFunctionV2
} from './types';

// tslint:disable-next-line:no-any
export class Layer<TOptions, TState = any, TReturn = any>
  implements LayerV2<TState, TOptions> {
  public readonly handle: LayerHandleV2<TState>;
  public readonly id: string;
  private closed: boolean = false;

  public constructor(
    private readonly _render: RenderFunctionV2<TState, TReturn>,
    private state: TState,
    private readonly contentOptions: LayerContentOptionsV2,
    public readonly options: LayerOptionsV2<TOptions, TState, TReturn>,
    private readonly removeFromState: (
      layer: LayerV2<TState, TOptions>
    ) => boolean,
    private readonly triggerUpdate: () => void
  ) {
    this.handle = {
      close: (): void => this.close(),
      update: (newState: TState): void => {
        this.state = newState;

        this.triggerUpdate();
      },
      isClosed: (): boolean => this.closed
    };

    this.id = `${this.options.id || 'layer'}-${uuid()}`;

    this.close = this.close.bind(this);
    this.render = this.render.bind(this);
  }

  public get isClosed(): boolean {
    return this.closed;
  }

  public close(result?: TReturn): void {
    if (this.closed) {
      return;
    }

    const wasCloseable = this.removeFromState(this);

    if (!wasCloseable) {
      // TODO notify parent that it does not exist anymore?
      return;
    }

    this.closed = true;

    if (this.options.onClose) {
      try {
        this.options.onClose(this.state, result, this.contentOptions);
      } catch (error) {
        console.error('A layer close callback threw an error:', error);
      }
    }

    this.triggerUpdate();
  }

  public render(): LayerContentElementV2 {
    return this._render(this.state, this.close, {
      ...this.contentOptions,
      id: this.id
    });
  }
}
