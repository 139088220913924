import {Subscription} from '@volkswagen-onehub/feature-hub-utils';
import {
  AlertLayerOptionsV1,
  FocusLayerOptionsV1,
  FocusLayerSizeV1,
  GfaLayerManagerV1,
  LayerHandleV1,
  LayerOptionsV1,
  RenderFunctionV1,
  ShimBackgroundTypeV1
} from '@volkswagen-onehub/gfa-layer-manager';
import {
  AlertLayerOptionsV2,
  FocusLayerOptionsV2,
  FocusLayerSizeV2,
  LayerManagerV25,
  LayerOptionsV2,
  ShimBackgroundTypeV2
} from '../v2';

export class GfaLayerManagerV1Impl implements GfaLayerManagerV1 {
  public constructor(private readonly delegate: LayerManagerV25) {}

  public openAlert<TState, TReturn>(
    render: RenderFunctionV1<TState, TReturn>,
    state: TState,
    options?: LayerOptionsV1<AlertLayerOptionsV1, TState, TReturn> | undefined
  ): LayerHandleV1<TState> {
    return this.delegate.openAlert(
      render,
      state,
      this.mapAlertOptions(options)
    );
  }
  public openFocusLayer<TState, TReturn>(
    render: RenderFunctionV1<TState, TReturn>,
    state: TState,
    options: LayerOptionsV1<FocusLayerOptionsV1, TState, TReturn>
  ): LayerHandleV1<TState> {
    return this.delegate.openFocusLayer(
      render,
      state,
      this.mapFocusOptions(options)
    );
  }
  public subscribe(
    listener:
      | ((payload: 'update') => void)
      | ((payload: 'afterRender') => void)
      | ((payload: 'beforeClose') => void)
  ): Subscription {
    return this.delegate.subscribe(listener);
  }

  private mapAlertOptions<TState, TReturn>(
    options?: LayerOptionsV1<AlertLayerOptionsV1, TState, TReturn>
  ): LayerOptionsV2<AlertLayerOptionsV2, TState, TReturn> | undefined {
    if (!options) {
      return undefined;
    }
    const shimBackgroundType = this.mapShimBackGroundType(
      options.shimBackgroundType
    );

    return {
      ...options,
      shimBackgroundType
    };
  }

  private mapFocusOptions<TState, TReturn>(
    options: LayerOptionsV1<FocusLayerOptionsV1, TState, TReturn>
  ): LayerOptionsV2<FocusLayerOptionsV2, TState, TReturn> {
    const shimBackgroundType = this.mapShimBackGroundType(
      options.shimBackgroundType
    );
    const size = this.mapFocusLayerSize(options.size);

    return {
      ...options,
      shimBackgroundType,
      size
    };
  }

  private mapShimBackGroundType(
    backgroundType?: ShimBackgroundTypeV1
  ): ShimBackgroundTypeV2 | undefined {
    if (!backgroundType) {
      return undefined;
    }
    switch (backgroundType) {
      case 'OPAQUE':
        return ShimBackgroundTypeV2.OPAQUE;
      case 'TRANSPARENT':
        return ShimBackgroundTypeV2.TRANSPARENT;
    }
  }

  private mapFocusLayerSize(size: FocusLayerSizeV1): FocusLayerSizeV2 {
    switch (size) {
      case 'A':
        return FocusLayerSizeV2.A;
      case 'B':
        return FocusLayerSizeV2.B;
    }
  }
}
